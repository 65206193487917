<template>
  <div class="container">
    <el-row class="shadow" style="background-color: #fff">
      <el-col :span="24"> <TimeZone style="padding: 10px" @change="dataChange" @changeDateZone="selectDateZone"></TimeZone></el-col>
    </el-row>
    <SplitPieChart class="pies" v-if="appIpData && appIpData.app_ip_pie" :sourceData="appIpData && appIpData.app_ip_pie" title="APP Statistics by Byte Amount" unit="Byte Amount"></SplitPieChart>
    <SplitPieChart class="pies" v-if="chartData && chartData.app_pie" :sourceData="chartData && chartData.app_pie" title="APP Statistics by Flow Amount" unit="Flow Amount"></SplitPieChart>
    <el-row>
      <!-- <div class="line mask"></div> -->
      <el-col :span="24" class="col line shadow">
        <Chart
          chartId="first2_line"
          :option="
            new OverViewAlloffTrafficLine({
              title: 'Statistics of All Traffic',
            }).getOption(['title', 'tooltip', 'xAxis', 'legend', 'yAxis', 'grid', 'series', 'dataZoom'])
          "
          :series="all_flow"
        ></Chart>
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col :span="24" class="col line shadow">
        <Chart
          chartId="first_line"
          :option="
            new LineOption({
              title: 'Traffic of Each APP (Kbps)',
              yunit: 'Kbps',
              legendTitle: [line_head.name],
            }).getOption(['title', 'tooltip', 'xAxis', 'yAxis', 'grid', 'legend', 'series', 'dataZoom'])
          "
          :extraOption="lineLegendByte"
          :series="byte_line"
          :EventList="{
            legendScroll: handleLegend,
          }"
          :watchSeries="initPage"
        ></Chart>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="col line shadow">
        <Chart
          chartId="second_line"
          :option="
            new LineOption({
              title: 'Traffic of Each APP (pps)',
              yunit: 'pps',
              legendTitle: [line_head.name],
            }).getOption(['title', 'tooltip', 'xAxis', 'yAxis', 'grid', 'legend', 'series', 'dataZoom'])
          "
          :extraOption="lineLegendPacket"
          :series="packet_line"
          :EventList="{
            legendScroll: handleLegend,
          }"
          :watchSeries="initPage"
        ></Chart>
      </el-col>
    </el-row> -->
    <div class="table-box shadow">
      <PaginationTable :originData="chartData && chartData.app_table" :showRule="showRule" :usePagination="false" :useHoverEvent="false" :defaultOrder="{ prop: 'sum_byte', order: 'descending' }"></PaginationTable>
    </div>
  </div>
</template>

<script>
// import Chart from '@/submodules/components/Chart'
import SplitPieChart from '@/components/SplitPieChart'
import TimeZone from '@/components/TimeZone'
import DynamicRoute from '@/components/DynamicRoute'
import NumberCol from '@/components/NumberCol'
// import PaginationTable from '@/submodules/components/PaginationTable'
import { LineOption, OverViewAlloffTrafficLine, handleLegend, initPage } from '@/templates/chart/lineTemplate'
import { getStatisticFlow, getStatisticAllFlow, getAppIpCount, getProtocolLine } from '@/api/FlowApi.js'
export default {
  name: 'OverView',
  components: {
    // Chart,
    SplitPieChart,
    TimeZone,
    // PaginationTable,
  },
  created() {
    this.ipVersion = localStorage.getItem('ip_version') || 'nf'
  },
  data() {
    return {
      DynamicRoute,
      ipVersion: 'nf',
      LineOption,
      OverViewAlloffTrafficLine,
      // dateArrayParam: [],
      all_flow: [],
      appIpData: [],
      lineLegendByte: {},
      lineLegendPacket: {},
      scrollIndex: 0,
      chartData: null,
      allFlowChartData: null,
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      showRule: [
        {
          label: 'App Name',
          subComponent: DynamicRoute,
          rowFormat: row => {
            return [`/app_view/${row.name}`, row.name]
          },
        },
        {
          prop: 'count',
          label: 'Flow Amount',
          sortable: true,
          compare: (a, b) => a.count - b.count,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.count
          },
        },
        {
          prop: 'sum_packet',
          label: 'Packet',
          sortable: 'custom',
          compare: (a, b) => a.sum_packet - b.sum_packet,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.sum_packet
          },
        },
        {
          prop: 'sum_byte',
          label: 'Byte',
          sortable: 'custom',
          compare: (a, b) => a.sum_byte - b.sum_byte,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.sum_byte
          },
        },
        {
          prop: 'Src_to_Dst_Packets',
          label: 'Client to Server Packets',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Src_to_Dst_Packets - b.Src_to_Dst_Packets,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_Packets
          },
        },
        {
          prop: 'Src_to_Dst_Bytes',
          label: 'Client to Server Bytes',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Src_to_Dst_Bytes - b.Src_to_Dst_Bytes,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_Bytes
          },
        },
        {
          prop: 'Dst_to_Src_Packets',
          label: 'Server to Client Packets',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Dst_to_Src_Packets - b.Dst_to_Src_Packets,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_Packets
          },
        },
        {
          prop: 'Dst_to_Src_Bytes',
          label: 'Server to Client Bytes',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Dst_to_Src_Bytes - b.Dst_to_Src_Bytes,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_Bytes
          },
        },
      ],
      line_head: {
        name: '||Min||Max||Avg',
        type: 'line',
        data: [],
        itemStyle: {
          color: '#00000000',
          opacity: 0,
        },
        lineStyle: {
          color: '#00000000',
          opacity: 0,
        },
        showSymbol: false,
      },
      protocolData: {},
      byte_line: [],
      packet_line: [],
      DataZone: parseInt(new Date().getTimezoneOffset() / 60),
      dateArray: [],
      colorList: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    }
  },
  mounted() {
    // // console.debug(this.userInfo ? 'DynamicRoute' : 'null')
  },
  props: {},
  methods: {
    initPage,
    handleLegend,
    // getShowRule() {
    //   if (this.userInfo) {
    //     this.showRule[0].subComponent = DynamicRoute
    //   } else {
    //     this.showRule[0].subComponent = null
    //   }
    //   return this.showRule
    // },
    async getProtocolLine() {
      // this.protocolData = await getProtocolLine(this.ipVersion, ...this.dateArray)
      // this.handlePacketLine()
      // this.handleByteLine()
    },
    async getAppIpCount() {
      // this.appIpData = await getAppIpCount(this.ipVersion, ...this.dateArray)
      // this.handleMasterPie()
    },
    async getStatisticFlow() {
      this.chartData = await getStatisticFlow(this.ipVersion, ...this.dateArray)
      // const all_flow_response = await getStatisticAllFlow(this.ipVersion, ...this.dateArray)
      // this.handleAllFlowLine()
      this.handleAppPie()
    },

    async getStatisticAllFlow() {
      this.allFlowChartData = await getStatisticAllFlow(this.ipVersion, ...this.dateArray)
      this.handleAllFlowLine()
    },
    handleMasterPie() {
      if (this.appIpData) {
        this.appIpData.app_ip_pie.sort((a, b) => {
          return b.value - a.value
        })
        this.appIpData.app_ip_pie = this.appIpData.app_ip_pie.map((item, index) => {
          item.itemStyle = {
            color: this.colorList[index % this.colorList.length],
          }
          item.name = `${item.name}||${item.value}`
          return item
        })
      }
    },
    handleAppPie() {
      if (this.chartData) {
        this.chartData.app_pie.sort((a, b) => {
          return b.value - a.value
        })
        this.chartData.app_pie = this.chartData.app_pie.map((item, index) => {
          item.itemStyle = {
            color: this.colorList[index % this.colorList.length],
          }
          item.name = `${item.name}||${item.value}`
          return item
        })
      }
    },
    handleAllFlowLine() {
      if (this.allFlowChartData['all_flow'].length !== 0) {
        this['all_flow'] = this.allFlowChartData['all_flow']
          .filter(item => item.data.length >= 1)
          .map((item, index) => {
            if (item.data.length !== 0) {
              item.type = 'line'
              // item.smooth = true
              // item.emphasis = {}
              item.zlevel = this.allFlowChartData['all_flow'].length - index
              item.areaStyle = {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: this.colorList[index], // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#fff', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              }
              item.data = item.data.map(this.transformDateWithTimeZone)
              item.showSymbol = item.data.length === 1 ? true : false
              return item
            }
          })
      } else {
        this['all_flow'] = []
      }
    },
    handleByteLine() {
      this.handleLine('byte_line', 'lineLegendByte')
    },
    handlePacketLine() {
      this.handleLine('packet_line', 'lineLegendPacket')
    },
    handleLine(lineData, lineLegend) {
      if (this.protocolData[lineData].length !== 0) {
        const lengedOption = {
          id: 'data',
          selected: {},
          data: [],
          scrollDataIndex: 0,
        }
        this[lineData] = this.protocolData[lineData]
          .filter(item => item.data.length >= 1)
          .map((item, index) => {
            if (item.data.length !== 0) {
              if (lineLegend) {
                lengedOption.selected[item.name] = index < 10 && index >= 0 ? true : false
                lengedOption.data.push(item.name)
              }
              item.type = 'line'
              item.smooth = true
              item.data = item.data.map(this.transformDateWithTimeZone)
              item.showSymbol = item.data.length === 1 ? true : false
              return item
            }
          })
        if (lineLegend) {
          this[lineLegend] = { legend: lengedOption }
        }
        this[lineData].push(this.line_head)
      } else {
        this[lineData] = []
      }
    },
    transformDateWithTimeZone(lineitem) {
      lineitem[0] = lineitem[0] - this.DataZone * 60 * 60 * 1000
      return lineitem
    },
    dataChange(dateArray, DataZone) {
      this.dateArray = dateArray
      this.DataZone = DataZone
      // this.dateArrayParam = [
      //   new Date(parseInt(dateArray[0])).toJSON(),
      //   new Date(parseInt(dateArray[1])).toJSON(),
      // ]
      this.getProtocolLine()
      this.getAppIpCount()
      this.getStatisticFlow()
      this.getStatisticAllFlow()
    },
    selectDateZone(newDateZone, dateArray) {
      this.DataZone = newDateZone
      this.dateArray = dateArray
      // this.dateArrayParam = [
      //   new Date(parseInt(dateArray[0]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      //   new Date(parseInt(dateArray[1]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      // ]
      this.getProtocolLine()
      this.getAppIpCount()
      this.getStatisticFlow()
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  --chart-height: 40vh;
  --chart-row-height: 50vh;
  .col {
    background-color: #ffffff;
    // background-color: #ffffff80;
  }

  .line {
    height: var(--chart-row-height);
    padding: 10px;
    // background-image: linear-gradient(to right, rgb(132, 9, 176), #fff);
  }
  .pie {
    height: 30vh;
    width: 50%;
  }
}

// .mask {
//   filter: drop-shadow(2px 4px 2px rgba(255, 255, 255, 0.492)) blur(10px);
// }
</style>
